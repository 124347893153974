<template>
  <div class="sendCard">
    <div class="send-schedule" v-if="!accountId">
      <steps :active="active" >
        <step>设置用卡要求</step>
        <step>填写发卡信息</step>
        <step>完成</step>
      </steps>
    </div>
    <div class="one-step" v-if="active == 0">
      <div class="one-step-hint" >
        <div class="one-step-hint-name" v-if="accountId">
          修改账户信息
        </div>
        <div class="one-step-hint-name" v-else>
          {{cardName}}
        </div>
        <div class="one-step-hint-cont">
          共享指定账户的资金，无需充值，直接消费
        </div>
      </div>
      <CellGroup title="消费验证" v-if="!accountId">
        <field
          v-model="value"
          label="消费密码"
          input-align="right"
        >
          <template slot="button">
            <vanSwitch size="18px" v-model="checked" />
          </template>
        </field>
        <field
          readonly
          clickable
          v-if="checked"
          v-model="password"
          label="密码设置"
          placeholder="请输入6位数字密码"
          input-align="right"
          @touchstart.native.stop="showkeyboard = true"
        />
      </CellGroup>
      <number-keyboard
        :show="showkeyboard"
        close-button-text="完成"
        @blur="showkeyboard = false"
        @input="onInput"
        @delete="onDelete"
      />
      <CellGroup title="消费限制">
        <field
          v-model="num_limit_amount"
          label="次限额"
          placeholder="输入次限额"
          type="digit"
          input-align="right"
        > 
          <template slot="button" v-if="num_limit_amount">
            <span>元</span>
          </template>
        </field>
        <field
          v-model="day_limit_amount"
          label="日限额"
          placeholder="输入日限额"
          type="digit"
          input-align="right"
        > 
          <template slot="button" v-if="day_limit_amount">
            <span>元</span>
          </template>
        </field>
        <field
          v-model="month_limit_amount"
          label="月限额"
          placeholder="输入月限额"
          type="digit"
          input-align="right"
        > 
          <template slot="button" v-if="month_limit_amount">
            <span>元</span>
          </template>
        </field>
        <field
          v-model="day_limit_num"
          label="每日限用次数"
          placeholder="输入每日限用次数"
          type="digit"
          input-align="right"
        > 
          <template slot="button" v-if="day_limit_num">
            <span>次</span>
          </template>
        </field>
        <field
          v-if="!accountId"
          type="number"
          v-model="balance"
          label="分配总金额"
          required
          placeholder="输入发卡金额"
          input-align="right"
        > 
          <template slot="button" v-if="balance">
            <span>元</span>
          </template>
        </field>
      </CellGroup>
      <Button type="primary" block class="MgnTop" @click="submitEdit" v-if="accountId">确定更改</Button>
      <Button type="info" block class="MgnTop" @click="active = 1" v-else>下一步</Button>

    </div>
    <div class="two-step" v-if="active == 1">
      <div class="last-step-infor">
        <div class="last-step-infor-name">{{cardName}}</div>
        <div class="last-step-infor-Cont">
          <p><span>密码</span><span>{{checked? '已设置':'未启用'}}</span></p>
          <!-- <p><span>消费限制</span><span>{{}}</span></p> -->
          <p><span>车卡一致</span><span>是</span></p>
        </div>
      </div>
      <CellGroup title="司机手机必须和微信卡包绑定的手机号一致">
        <field
          type="tel"
          v-model="mobile"
          label="手机号码"
          required
          placeholder="输入手机号码"
          input-align="right"
          :rules="[{  pattern, message: '请输入正确手机号',trigger: 'onChange' }]"
        />
        <field
          v-model="contact_name"
          label="司机姓名"
          placeholder="输入司机姓名"
          input-align="right"
        />
        <field
          v-model="car_num"
          label="车牌号码"
          required
          placeholder="输入车牌号码"
          input-align="right"
        />
      </CellGroup>
      <div class="button-group">
        <Button type="info" class="buttom-item" @click="active = 0">上一步</Button>
        <Button type="primary" class="buttom-item" @click="sendCard">完成</Button>
      </div>
    </div>
  </div>
</template>

<script>
import creditshop from "@/api/creditshop"
import { Step, Steps, Field,CellGroup,Switch, Button, NumberKeyboard } from 'vant';
export default {
  components: {
    Step,
    Steps,
    Field,
    CellGroup,
    vanSwitch: Switch,
    Button,
    NumberKeyboard,
  },
  data() {
    return {
      active: 0,
      checked: false,
      value: "",
      phone: "",
      password: "",
      showkeyboard: false,
      num_limit_amount: "",
      day_limit_amount: "",
      month_limit_amount: "",
      day_limit_num: "",
      mobile: "",
      contact_name: "",
      car_num: "",
      balance: "",
      selectCard: false,
      columns:["加油卡","汽油卡"],
      pattern: /^1(3|4|5|6|7|8|9)\d{9}/,
      cardName: "",
      accountId: ""
    }
  },
  watch: {
    checked(val) {
      if (!val) {
        this.password = "";
      }
    }
  },
  mounted() {
    if (this.$route.query.hasOwnProperty("accountId")) { // 编辑
      this.accountId = this.$route.query.accountId;
      this.getDetail();
    } else { // 新建
      this.cardName = this.$route.query.cardName;
    }
    
  },
  methods: {
    async submitEdit() {
      try {
        const res = await creditshop.Team_Card_edit(
          this.accountId,
          this.num_limit_amount,
          this.day_limit_amount,
          this.month_limit_amount,
          this.day_limit_num,
          
        )
        if (res.code == 200) {
          this.$toast({
            type: "success",
            message: res.msg
          });
          this.$router.go(-1)
        } else {
          this.$toast({
            message: res.msg
          })
          
        }
      } catch(err) {
        console.log(err)
      }
    },
    // 获取详情
    async getDetail() {
      try {
        const res = await creditshop.Team_Card_detial(this.accountId);
        if (res.code == 200) {
          if (res.data.is_pass == 1) {
            this.checked = true;
            this.password = res.data.password;
          } else {
            this.checked = false;
          }
          this.num_limit_amount = parseInt(res.data.num_limit_amount)
          this.day_limit_amount =  parseInt(res.data.day_limit_amount)
          this.month_limit_amount =  parseInt(res.data.month_limit_amount)
          this.day_limit_num =  parseInt(res.data.day_limit_num)
        }
      } catch (error) {
        console.log(error)
      }
    },
    onInput(val) {
      if (this.password.length>=6) {
        this.password = this.password.substring(0, 6)
      } else {
        this.password = this.password + val
      }
    },
    onDelete() {
      this.password = this.password.substring(0, this.password.length-1)
    },
    // 完成发卡
    async sendCard() {
      try {
        if (this.checked) {
          if (this.password.length!=6) {
            this.$toast("请输入6位数字密码");
            return;
          }
        }
        const res = await creditshop.team_send_card(
          localStorage.getItem("info_uid"),
          this.checked?1:0,
          this.password,
          this.num_limit_amount,
          this.day_limit_amount,
          this.month_limit_amount,
          this.day_limit_num,
          this.balance,
          this.mobile,
          this.car_num,
          this.contact_name,
          this.$route.query.id
        )
        if (res.code == 200) {
          this.$toast({
            type: "success",
            message: res.msg
          });
          this.$router.push("/sendCardSuccess")
        } else {
          this.$toast({
            message: res.msg
          })
          
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sendCard {
  height: 100%;
  background: #F2F4F7;
  .one-step {
    .one-step-hint {
      width: 355px;
      margin: 10px auto;
      background: #fff;
      padding: 15px 18px;
      border-radius: 5px;
      .one-step-hint-name {
        font-size: 15px;
        font-weight: bold;
        color: #333;
        height: 16px;
        line-height: 16px;
        margin-bottom: 5px;
      }
      .one-step-hint-cont {
        color: #D8D8D8;
        font-size: 12px;
        
      }
    }
    .MgnTop {
      margin-top: 15px;
    }
  }
  .two-step {
    .last-step-infor {
      width: 355px;
      margin: 10px auto;
      background: #fff;
      padding: 15px 18px;
      border-radius: 5px;
      display: flex;

      .last-step-infor-name {
        // width: 80px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 16px;
        font-weight: bold;
        border-right: 1px solid #F7F7F7;
      }
      .last-step-infor-Cont {
        flex: 1;
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        > p {
          color: #666;
          display: flex;
          justify-content: space-between;
        }
      }
    }
    .button-group {
      padding: 15px;
      display: flex;
      justify-content: space-between;
      .buttom-item {
        width: 45%;
      }
    }
  }
}
</style>

<style lang="scss">
.sendCard {
  .van-field__button {
    display: flex;
  }
  .van-steps {
    padding-top: 30px;
  }
}
</style>
